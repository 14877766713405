.App {
  text-align: center;
   font-family: Roboto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mulitColor {
  text-align: center;
  position: relative;
  /* line-height: 100px; */
  background: #fff;
  height: 300px;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mulitColor:after {
  background: linear-gradient(
    to right,
    #bcbcbc 25%,
    #ffcd02 25%,
    #ffcd02 50%,
    #e84f47 50%,
    #e84f47 75%,
    #65c1ac 75%
  );
  position: absolute;
  content: "";
  height: 4px;
  right: 0;
  left: 0;
  top: 0;
}

#buttonLabel {
  position: relative;
}
.MuiSelect-icon {
  top: calc(50% - 12px);
  color: white !important;
  right: 0;
  position: absolute;
  pointer-events: none;
}
#buttonLabel:after {
  font: 12px "Consolas", monospace;
  color: black;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  right: 8px;
  top: 2px;
  vertical-align: center;
  padding: 0 0 2px;
  border-bottom: none;
  position: absolute;
}
select {
  padding: 3px;
  margin: 0;
  width: 120px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 3px 0 #ccc, 0 -1px #fff inset;
  -moz-box-shadow: 0 3px 0 #ccc, 0 -1px #fff inset;
  box-shadow: 0 3px 0 #ccc, 0 -1px #fff inset; */
  background: #f8f8f8;
  color: #888;
  border: none;
  outline: none;
  display: inline-block;
  /* -webkit-appearance: none;
  -moz-appearance: none; */
  /* appearance: none; */
  cursor: pointer;
}

.dropDownMenu {
  margin-top: -4px !important;
  min-width: 145px !important;
  max-height:50vh;
  overflow:auto;
}
.btn-success.dropdown-toggle {
  margin-top: -20px;
  width: 80px;
  color: black !important;
  background-color: transparent !important;
  border-color: transparent !important;
  margin-left: 5px;
}
.btn-success.dropdown-toggle:focus {
  box-shadow: none !important;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 5.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropDownToggleSmall::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.MuiAvatar-root {
  display: flex;
  overflow: hidden;
  position: relative;
  font-size: 1.25rem;
  align-items: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1;
  border-radius: 50%;
  justify-content: center;
  -moz-user-select: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.MuiBadge-anchorOriginTopLeftCircle {
  top: 24% !important;
  left: 14%;
  transform: scale(1) translate(-50%, -50%);
  transform-origin: 0% 0%;
}

.MuiPaginationItem-textSecondary.Mui-selected:hover,
.MuiPaginationItem-textSecondary.Mui-selected.Mui-focusVisible {
  background-color: #ffb600 !important;
}

.MuiPaginationItem-textSecondary.Mui-selected,
.MuiPaginationItem-textSecondary.Mui-selected.Mui-focusVisible {
  background-color: #ffb600 !important;
}

#or {
  overflow: hidden;
  text-align: center;
}
#or:before,
#or:after {
  background-color: #333;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
#or:before {
  right: 0.5em;
  margin-left: -50%;
  margin-top: 11px;
}
#or:after {
  left: 0.5em;
  margin-right: -50%;
  margin-top: 11px;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn {
  border: 1px solid #ffb600;
  color: #ffb600;
  background-color: white;
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 20px;
}
.btnFilled {
  border: none;
  background-color: #ffb600;
  color: white;
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 20px;
}
.btnFilledRed {
  border: none;
  background-color: #f9655e;
  color: white;
  padding: 8px 15px;
  border-radius: 8px;
  font-size: 20px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.timezone-picker-textfield input {
  width: 100%;
  padding: 9px 12px;
  font: inherit;
  box-sizing: border-box;
  outline: 0;
  background: #fff;
  border: none;
  border-radius: 2px;
}

.form-control:focus {
  box-shadow: unset;
}

.timezone-picker-list {
  background-color: white;
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: none;
}
label {
  display: inline-block;
  margin-bottom: 0.2rem;
}

.react-calendar {
  width: 80%;
  margin-top: 20px;
  background-color: transparent;
}
button.rec-dot {
  box-shadow: 0 0 1px 3px #ffb600;
  background-color: #ffb600;
  width: "20rem";
}

button.rec-dot:hover,
button.rec-dot:active,
button.rec-dot:focus {
  box-shadow: 0 0 1px 3px green;
}

a:hover {
  text-decoration: none;
  color: #FFB600;
}