:root {
    --primary: '#ffb600'
}


.fc-button {
    box-shadow: none !important;
}

.fc-header-toolbar {
    padding: 24px;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
}

.fc-toolbar-chunk {
    display: flex;
    align-items: center;
}

.fc-prev-button {
    background: white !important;
    border: 2px solid #f0f2f3 !important;
    color: #87929b !important;
    padding: 10px 12px !important;
    border-radius: 5px 0 0 5px !important;
}

.fc-next-button {
    background: white !important;
    border: 2px solid #f0f2f3 !important;
    color: #87929b !important;
    padding: 10px 12px !important;
    border-radius: 0 5px 5px 0 !important;
}

.fc-toolbar-title {
    color: #6f757b !important;
    font-size: 22px !important;
    font-weight: 400 !important;
}

.fc-today-button {
    color: #87929b !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    line-height: normal !important;
    border: 2px solid #d4d8db !important;
    background: white !important;
}

.fc-button.fc-timeGridWeek-button, .fc-button.fc-listWeek-button {
    border-radius: 0 !important;
    border: none !important;
    background: white !important;
    color: #6f757b !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    padding: 10px 20px !important;
    border-bottom: 3px solid #dee3e4 !important;
}

.fc-button-active.fc-timeGridWeek-button, .fc-button-active.fc-listWeek-button {
    color: #384047 !important;
    border-bottom: 4px solid #ffb600 !important;
}

.fc-col-header-cell-cushion {
    text-transform: capitalize;
    color: #6f757b;
    padding: 6px 0;
    font-size: 19px !important;
    font-weight: 400 !important;
    cursor: pointer;
}

.fc .fc-timegrid-slot {
    height: 3rem;
}

.fc .fc-timegrid-slot {
    border-bottom: none !important;
    border-top-style: dotted !important;
}
.fc .fc-timegrid-slot-minor:nth-child(1){
    border-top-style: none !important;
}

.fc .fc-timegrid-slot-label {
    border-top-style: none !important;
}

.fc .fc-timegrid-slot-label-cushion {
    color: #6f757b !important;
    padding: 0 20px 0 12px !important;
    font-size: 19px !important;
    font-weight: 400 !important;
}