:root {
    --primary: '#ffb600'
}

.fc-col-header-cell-cushion {
    text-transform: capitalize;
    color: #6f757b;
    padding: 6px 0;
    font-size: 19px !important;
    font-weight: 400 !important;
    cursor: pointer;
}

@media (max-width: 767px) {
    .fc-col-header-cell-cushion {
        font-size: 14px !important;
    }
}

.fc .fc-timegrid-slot {
    height: 3rem;
}

.fc .fc-timegrid-slot {
    border-bottom: none !important;
    border-top-style: dotted !important;
}
.fc .fc-timegrid-slot-minor:nth-child(1){
    border-top-style: none !important;
}

.fc .fc-timegrid-slot-label {
    border-top-style: none !important;
}

.fc .fc-timegrid-slot-label-cushion {
    color: #6f757b !important;
    padding: 0 20px 0 12px !important;
    font-size: 19px !important;
    font-weight: 400 !important;
    margin-top: -15px !important;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    margin-top: -23px !important;
}

.fc-v-event {
    border: none !important;
    border-radius: 0 !important;
}